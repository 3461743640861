// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\Users\\Sylvain\\debleeckere\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-index-jsx": () => import("C:\\Users\\Sylvain\\debleeckere\\src\\templates\\index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-post-jsx": () => import("C:\\Users\\Sylvain\\debleeckere\\src\\templates\\post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-tag-jsx": () => import("C:\\Users\\Sylvain\\debleeckere\\src\\templates\\tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */),
  "component---src-templates-category-jsx": () => import("C:\\Users\\Sylvain\\debleeckere\\src\\templates\\category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-author-jsx": () => import("C:\\Users\\Sylvain\\debleeckere\\src\\templates\\author.jsx" /* webpackChunkName: "component---src-templates-author-jsx" */),
  "component---src-templates-person-jsx": () => import("C:\\Users\\Sylvain\\debleeckere\\src\\templates\\person.jsx" /* webpackChunkName: "component---src-templates-person-jsx" */),
  "component---src-templates-documenten-jsx": () => import("C:\\Users\\Sylvain\\debleeckere\\src\\templates\\documenten.jsx" /* webpackChunkName: "component---src-templates-documenten-jsx" */),
  "component---src-templates-person-detail-jsx": () => import("C:\\Users\\Sylvain\\debleeckere\\src\\templates\\person_detail.jsx" /* webpackChunkName: "component---src-templates-person-detail-jsx" */),
  "component---src-pages-bakermat-jsx": () => import("C:\\Users\\Sylvain\\debleeckere\\src\\pages\\bakermat.jsx" /* webpackChunkName: "component---src-pages-bakermat-jsx" */),
  "component---src-pages-contact-jsx": () => import("C:\\Users\\Sylvain\\debleeckere\\src\\pages\\contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-documenten-jsx": () => import("C:\\Users\\Sylvain\\debleeckere\\src\\pages\\documenten.jsx" /* webpackChunkName: "component---src-pages-documenten-jsx" */),
  "component---src-pages-familie-jsx": () => import("C:\\Users\\Sylvain\\debleeckere\\src\\pages\\familie.jsx" /* webpackChunkName: "component---src-pages-familie-jsx" */),
  "component---src-pages-onderzoek-jsx": () => import("C:\\Users\\Sylvain\\debleeckere\\src\\pages\\onderzoek.jsx" /* webpackChunkName: "component---src-pages-onderzoek-jsx" */)
}

exports.data = () => import("C:\\Users\\Sylvain\\debleeckere\\.cache\\data.json")

